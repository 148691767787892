import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import logo from "../AccountDetails/analytics-logo.png";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import {useHistory} from "react-router";
import {makeStyles} from "@material-ui/core/styles";


export const AnalyticsAccount = ({settings}) => {
    const history = useHistory();
    if (!settings.tokenName) return null;
    return <List>
        <ListItem>
            <ListItemAvatar>
                <Avatar variant="square" src={logo}/>
            </ListItemAvatar>
            <ListItemText primary={settings.analyticsAccountOwner}
                          secondary="GA4 Account"/>
            <ListItemSecondaryAction>
                <Tooltip title="Manage Analytics accounts">
                    <IconButton edge="end" aria-label="delete" onClick={() => {
                        history.push("/account/google-analytics")
                    }}>
                        <MoreHorizIcon/>
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    </List>;
}

const useStyles = makeStyles((theme) => ({
    analyticsToken: {
        "& .MuiListItemText-primary": {
            fontSize: '0.9rem'
        },
        "& .MuiListItemText-secondary": {
            fontSize: '0.6rem'
        },
        "& .MuiListItemAvatar-root": {
            minWidth: "35px"
        },
        "&.MuiListItem-gutters": {
            padding: 0
        }
    }, analyticsButtonIcon: {
        width: "28px",
        height: "28px",

    }
}));

export const AnalyticsToken = ({token}) => {

    const classes = useStyles();

    if (!token.name) return null;
    return <ListItem className={classes.analyticsToken}>
        <ListItemAvatar>
            <Avatar className={classes.analyticsButtonIcon} variant="square" src={logo}/>
        </ListItemAvatar>
        <ListItemText primary={token.analyticsAccountOwner} secondary="GA4 Account"/>
    </ListItem>;
}
