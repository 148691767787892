import React from "react";
import { AnalyticsAccount } from "./AnalyticsAccount";
import { Chip, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
 
    propertiesContainer: {
        border: '1px solid #ddd',
        borderRadius: '4px', 
        marginTop: '16px', 
        marginBottom: '16px', 
        backgroundColor: '#ffffff', 
        padding: '16px', 
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #ddd', 
        padding: '12px 0', 
        '&:last-child': {
            borderBottom: 'none', 
        },
    },
    label: {
        width: '20%',
        fontWeight: 'bold',
        fontSize: '1rem',
        color: '#333', 
    },
    value: {
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        color: '#555', 
    },
    chip: {
        marginRight: '8px', 
        backgroundColor: '#e0f7fa', 
        color: '#006064', 
    },
    accountRow: {
        padding: '16px', 
        backgroundColor: '#ffffff', 
        borderRadius: '4px',
    },
 
});

export const AnalyticsViewDetails = ({ settings }) => {
    const classes = useStyles();

    if (!settings.tokenName) return null;

    return (<>
  
            <div className={classes.accountRow}>
                <AnalyticsAccount settings={settings} />
            </div>
            <div className={classes.propertiesContainer}>
                {renderProperty("Account", settings.accountId, settings.accountDisplayName)}
                {renderProperty("Property", settings.propertyId, settings.propertyDisplayName)}
            </div>
       
        </>
    );

    function renderProperty(key, id, displayName) {
        return (
            <div className={classes.row} key={key}>
                <div className={classes.label}>
                    {key}
                </div>
                <div className={classes.value}>
                    <Chip
                        variant="outlined"
                        size="small"
                        label={id}
                        className={classes.chip}
                    />
                    {displayName}
                </div>
            </div>
        );
    }
};
