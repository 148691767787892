import React, { useEffect, useState } from "react";
import apiFetch from "../../util/fetch-utils";
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import { makeStyles, Tooltip } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { AnalyticsToken } from "../AnalyticsAccount";
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useLocation } from "react-router";
import { NoGAConnected } from "./SelectSubModules";

const useStyles = makeStyles((theme) => ({
    invalidTokenFeedback: {
        width: "100%",
        "margin-top": "0.25rem",
        "font-size": "80 %",
        color: "#dc3545",
    },
    "noOptionsMessage": {
        color: "hsl(0,0%,60%)",
        padding: "8px 12px",
        textAlign: "center",
        boxSizing: "border-box"
    }, button: {
        marginLeft: theme.spacing(1)
    }, accountSelect: {
        width: "100%"
    }
}));

export const AnalyticsSettingsModal = (props) => {

    const analyticsSettings = props.analyticsSettings || {};

    const [analyticsData, setAnalyticsData] = useState([]);
    const [analyticsToken, setAnalyticsToken] = useState({});
    const [analyticsAccount, setAnalyticsAccount] = useState({});
    const [analyticsProperty, setAnalyticsProperty] = useState({});

    const [accountSummariesLoading, setAccountSummariesLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();
    const tokenName = analyticsToken.value;
    const modalIsOpen = props.isOpen;

    useEffect(() => {
        if (!modalIsOpen) {
            loadDefaults();
        }

    }, [analyticsData, analyticsSettings, analyticsToken, analyticsAccount, analyticsProperty]);


    useEffect(() => {
        if (!props.userDetails.analyticsTokens) return;

        const newTokenName = getParam(location.search, "newTokenName");
        const t = props.userDetails.analyticsTokens
            .find(token => token.name === newTokenName);
        if (t) {
            setAnalyticsToken(renderToken(t));
        }

    }, [location, props.userDetails.analyticsTokens])

    useEffect(() => {

        if (!tokenName) return;


        let url = "/api/google-analytics/account-summaries?tokenName=" + tokenName;

        setAccountSummariesLoading(true);
        setAnalyticsData([]);
        setLoadingError(null);

        apiFetch(url)
            .then(res => {
                if (res.status !== 200) throw new Error();
                return res.json();
            })

            .then(
                (result) => {
                    setAnalyticsData(result);
                }, (error) => {
                    setLoadingError(error);
                }
            ).finally(() => {
                setAccountSummariesLoading(false);
            })
    }, [tokenName, analyticsToken.id]);

    

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size={"xl"}>
            <ModalHeader toggle={props.toggle}>Google Analytics connection</ModalHeader>
            <ModalBody>
                <FormGroup row inline>
                    <Label for="value" sm={3}>Analytics Connection</Label>
                    <Col sm={9}>
                        <Grid wrap={"nowrap"} container direction="row" justifyContent="flex-start" alignItems="center">
                            <Select className={classes.accountSelect}
                                onChange={data => {
                                    setLoadingError(false);

                                    setAnalyticsToken(data);
                                    setAnalyticsAccount({});
                                    setAnalyticsProperty({});
                                }}
                                value={analyticsToken}
                                components={{ NoOptionsMessage: NoGAConnected(classes, addAnalyticsAccount) }}
                                options={renderTokensToOptions()} />

                            <Tooltip title="Add Analytics Connection">
                                <IconButton color="primary" onClick={addAnalyticsAccount}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Manage Analytics Connections">
                                <IconButton color="primary" onClick={gotoManageAnalyticsAccounts}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {loadingError && InvalidAuthAlert(classes.invalidTokenFeedback)}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="value" sm={3}>Account</Label>
                    <Col sm={9}>
                        <Select isClearable={true}
                            isLoading={accountSummariesLoading}
                            value={analyticsAccount}
                            // components={{NoOptionsMessage: NoViewsAvailable(classes, analyticsViews, setFilters)}}
                            onChange={data => {
                                setAnalyticsAccount(data);
                                setAnalyticsProperty({});
                            }}
                            options={renderAccountsToOptions()}
                        />

                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="value" sm={3}>Property</Label>
                    <Col sm={9}>

                        <Select isClearable={true}
                            isLoading={accountSummariesLoading}
                            value={analyticsProperty}
                            // components={{ NoOptionsMessage: NoViewsAvailable(classes, analyticsData, setFilters) }}
                            onChange={data => {
                                setAnalyticsProperty(data);
                            }}
                            options={renderPropertiesToOptions()}
                        />

                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={!analyticsProperty} onClick={onOk}>Ok</Button>
                <Button color="secondary" onClick={props.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );

    function renderAccountsToOptions() {
        return analyticsData.map((summary) => (
            {
                "label": <>
                    <Chip variant="outlined" size="small" label={summary.account.name.replaceAll("accounts/", "")} />
                    {" - "}
                    {summary.account.displayName}
                </>,
                "value": summary.account.name,
                "displayName": summary.account.displayName
            })
        );
    }

    function renderPropertiesToOptions() {

        if (!analyticsAccount || !analyticsAccount.value) {
            return [];
        }

        // Find the correct account by name
        const account = analyticsData.find((account) => account.account.name === analyticsAccount.value);


        if (!account) return [];


        return account.properties.map((property) => ({
            label: (
                <>
                    <Chip
                        variant="outlined"
                        size="small"
                        label={property.name.replace("properties/", "")}
                    />
                    {" - "}
                    {property.displayName}
                </>
            ),
            value: property.name,
            displayName: property.displayName
        }));
    }

    function getParam(query, paramName) {
        if (!query.includes("?") || !query.includes(paramName)) return;
        return query.slice(1)
            .split("&")
            .map(value => value.split("="))
            .find(value => value[0] === paramName)[1];
    }

    function addAnalyticsAccount() {
        window.location.href = buildRedirectUrl();
    }

    function buildRedirectUrl() {
        let curUrl = new URL(window.location.href);
        let curHash = curUrl.hash;
        curUrl.hash = curUrl.hash + (curHash.includes("?") ? "&" : "?") + "selectAnalyticsViewOpen=true";
        return "/api/google-analytics/authorize?redirectUrl=" + encodeURIComponent(curUrl.toString());
    }

    function gotoManageAnalyticsAccounts() {
        history.push("/account/google-analytics");
    }

    function loadDefaults() {

        //This gets called when the user just added a new token. Intent is to add a connection around the new token so we don't pre-populate
        if (getParam(location.search, "newTokenName")) return;


        if (analyticsSettings.tokenName) {
            if (!analyticsToken.value) {
                setAnalyticsToken(renderTokensToOptions().find(option => option.value == analyticsSettings.tokenName));
            }

            if (analyticsData.length > 0) {
                if (analyticsSettings.accountId && !analyticsAccount.value) {
                    setAnalyticsAccount(renderAccountsToOptions().find(option => option.value == "accounts/" + analyticsSettings.accountId));
                }

                if (analyticsSettings.propertyId && analyticsAccount.value && !analyticsProperty.value) {
                    setAnalyticsProperty(renderPropertiesToOptions().find(option => option.value == "properties/" + analyticsSettings.propertyId));
                }
            }
        }

    }

    function renderTokensToOptions() {
        if (!props.userDetails.analyticsTokens) return [];
        return props.userDetails.analyticsTokens.map(token => renderToken(token));
    }

    function onOk() {
        let settings = {
            tokenName: analyticsToken.value,
            analyticsAccountOwner: analyticsToken.analyticsAccountOwner,
            accountId: analyticsAccount.value.replace("accounts/", ""),
            accountDisplayName: analyticsAccount.displayName,
            propertyId: analyticsProperty.value.replace("properties/", ""),
            propertyDisplayName: analyticsProperty.displayName
        };
        props.setAnalyticsSettings(settings);
        props.toggle();
    }

    function renderToken(token) {
        return {
            value: token.name, label: <AnalyticsToken token={token} />, id: token.id, analyticsAccountOwner: token.analyticsAccountOwner
        }
    }
    
};


AnalyticsSettingsModal.defaultProps = {
    analyticsSettings: {},
    userDetails: {
        analyticsTokens: []
    }
};

const InvalidAuthAlert = (style) => <div className={style}>Invalid Analytics Authorization.</div>;
