import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { makeStyles } from "@material-ui/core";
import { AnalyticsSettingsModal } from "./AnalyticsSettingsModal";
import { AnalyticsViewDetails } from "../AnalyticsViewDetails";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";


const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#f9f9f9', // Light background to make the content pop
        borderRadius: '8px', // Slightly larger border radius for a modern look
        padding: '24px', // Add padding around the container for better spacing
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow for depth
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '16px',
        gap: '16px',
    },
    table: {
        minWidth: 80,
        "margin-bottom": "1em"
    }, tableHead: {
        fontWeight: 800,
        width: "10em"
    }
});

export default function GoogleAnalyticsSelect(props) {

    const classes = useStyles();
    const location = useLocation();
    const [isModalOpenState, setModalOpenState] = useState(false);
    const userDetails = useSelector(state => state.login.userDetails);


    useEffect(() => {
        if (location.search.includes("selectAnalyticsViewOpen=true")) {
            setModalOpenState(true);
        }
    }, [location.search])


    let settings = props.ws.analyticsSettings || {};

    const userIsWorkspaceOwner = props.ws.ownerName === userDetails.username;
    return (
        <div className={classes.container}>
            <AnalyticsViewDetails settings={settings} classes={classes} />

            <div className={classes.buttonGroup}>
                <Button disabled={!userIsWorkspaceOwner} color="primary" 
                    onClick={() => setModalOpenState(true)}>Select</Button>
                <Button disabled={!userIsWorkspaceOwner} color="danger" outline
                    onClick={() => props.setAnalyticsSettings(null)}>Remove</Button>
            </div>

            <AnalyticsSettingsModal
                userIsWorkspaceOwner={userIsWorkspaceOwner}
                analyticsSettings={props.ws.analyticsSettings}
                workspaceNumber={props.ws.seqNumber}
                setAnalyticsSettings={props.setAnalyticsSettings}
                workspaceWebsiteUrl={props.ws.websiteUrl}
                userDetails={userDetails}
                isOpen={isModalOpenState} toggle={toggle} />
        </div>
    );

    function toggle() {
        setModalOpenState(!isModalOpenState);
    }
}